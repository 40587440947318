<template>
  <div
    style="height: inherit"
  >
    <!-- View header -->
    <b-card
      no-body
      class="p-2"
    >
      <b-row class="align-items-center justify-content-between">
        <b-col
          sm="12"
          md="6"
        >
          <h5 class="mb-sm-1">
            Tournament bracket
          </h5>
        </b-col>
      </b-row>
    </b-card>
    <!-- /View header -->

    <b-row
      v-if="isLoading"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </b-row>

    <vue-perfect-scrollbar
      v-else
      :settings="perfectScrollbarSettings"
      class="ps-customizer-area scroll-area h-100"
    >
      <b-row class="mb-2">
        <b-col>
          <organizer-bracket
            :key="1"
            :add-grand-finale-round="true"
            :flat-tree="winnerNodes"
            :show-edit-results="canEditResults"
            :is-loading-match="isLoadingMatch"
            :loading-match-id="loadingMatchId"
            @onEditRoundSettingsClick="handleEditRoundSettingsClick"
            @onMatchInfoModalShow="handleMatchInfoModalShow"
          >
            <template #player="{ player }">
              {{ player.name }}
            </template>
          </organizer-bracket>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col>
          <organizer-bracket
            :key="2"
            :flat-tree="looserNodes"
            :show-edit-results="canEditResults"
            :is-loading-match="isLoadingMatch"
            :loading-match-id="loadingMatchId"
            @onEditRoundSettingsClick="handleEditRoundSettingsClick"
            @onMatchInfoModalShow="handleMatchInfoModalShow"
          >
            <template #player="{ player }">
              {{ player.name }}
            </template>
          </organizer-bracket>
        </b-col>
      </b-row>

    </vue-perfect-scrollbar>

    <round-settings-modal
      :round="selectedRound"
      @onRoundSettingsSaved="handleRefreshBracket"
    />

    <organizer-match-modal :match="selectedMatch" /></div>
</template>
<script>
import {
  BCard, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import OrganizerBracket from '@/components/bracket/OrganizerBracket.vue'
import RoundSettingsModal from '@/views/dashboard/tournament/RoundSettingsModal.vue'
import OrganizerMatchModal from '@/views/dashboard/OrganizerMatchModal.vue'

export default {
  components: {
    OrganizerMatchModal,
    OrganizerBracket,
    BSpinner,
    BCard,
    BRow,
    BCol,
    VuePerfectScrollbar,
    RoundSettingsModal,
  },
  data() {
    return {
      participants: [],
      searchQuery: '',
      canEditResults: false,
      perfectScrollbarSettings: {
      },
      bracket: null,
      selectedRound: null,
      isLoading: false,
      isLoadingMatch: false,
      loadingMatchId: null,
      selectedMatch: null,
    }
  },
  computed: {
    showTeamWaitingApprovalCard() {
      return false
    },
    showJoinTournamentCard() {
      return true
    },
    winnerNodes() {
      return this.bracket?.filter(node => node.group.index === 1)
        ?.map(node => ({
          id: node.id,
          next: node.next_match_id,
          matchId: node.match_id,
          roundId: node.round_id,
          round: node.round,
          player1: {
            id: node.opponent1?.participant?.id,
            name: node.opponent1?.participant?.name || null,
            score: node.result?.opponent1_score,
            result: node.result?.opponent1_result || null,
          },
          player2: {
            id: node.opponent2?.participant?.id,
            name: node.opponent2?.participant?.name || null,
            score: node.result?.opponent2_score,
            result: node.result?.opponent2_result || null,
          },
        }))
    },
    looserNodes() {
      return this.bracket?.filter(node => node.group.index === -1)
        ?.map((node, index, array) => ({
          id: node.id,
          next: index === array.length - 1 ? null : node.next_match_id,
          matchId: node.match_id,
          roundId: node.round_id,
          round: node.round,
          player1: {
            id: node.opponent1?.participant?.id || null,
            name: node.opponent1?.participant?.name || null,
            score: node.result?.opponent1_score || null,
            result: node.result?.opponent1_result || null,
          },
          player2: {
            id: node.opponent2?.participant?.id || null,
            name: node.opponent2?.participant?.name || null,
            score: node.result?.opponent2_score || null,
            result: node.result?.opponent2_result || null,
          },
        }))
    },
  },
  async created() {
    await this.$store.dispatch('tournament/fetchTournament', this.$route.params.id)

    await this.fetchBrackets()
  },
  methods: {
    async fetchMatch(matchId) {
      this.isLoadingMatch = true
      this.loadingMatchId = matchId
      const { data } = await this.$api.tournament.matchById(matchId)

      this.isLoadingMatch = false
      return data
    },
    handleEditRoundSettingsClick(roundId) {
      const round = this.bracket?.find(node => node.round_id === roundId)?.round

      this.selectedRound = JSON.parse(JSON.stringify(round))

      this.$bvModal.show('round-settings-modal')
    },
    async handleMatchInfoModalShow(matchId) {
      this.selectedMatch = await this.fetchMatch(matchId)

      this.$bvModal.show('organizer-match-modal')
    },
    async fetchBrackets() {
      this.isLoading = true
      const { data } = await this.$api.tournament.fetchBracket(this.$route.params.tournamentId)
      this.isLoading = false
      this.bracket = data
    },
    handleRefreshBracket() {
      this.fetchBrackets()
    },
  },
}

</script>

<style lang="scss">

</style>
