var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "round-settings-modal",
      "title": _vm.title,
      "size": "md"
    },
    on: {
      "hidden": _vm.resetModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-row', {
          staticClass: "flex-grow-1"
        }, [_c('b-col', [_c('dismissible-message-box', {
          staticClass: "p-1",
          attrs: {
            "show": _vm.showNotifications,
            "variant": _vm.notificationType
          },
          on: {
            "onDismissibleMessageBoxDismissed": function onDismissibleMessageBoxDismissed($event) {
              _vm.showNotifications = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.message) + " ")], 1)], 1), _c('b-col', [_c('spinning-button', {
          attrs: {
            "is-loading": _vm.saving
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.handleSaveSettings($event);
            }
          }
        }, [_vm._v(" Save ")]), _c('b-button', {
          staticClass: "ml-1",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)], 1)];
      }
    }])
  }, [_c('b-row', {
    staticClass: "flex-column"
  }, [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Title",
      "label-for": "title"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-1",
    attrs: {
      "id": "title",
      "placeholder": "name"
    },
    model: {
      value: _vm.roundLocal.name,
      callback: function callback($$v) {
        _vm.$set(_vm.roundLocal, "name", $$v);
      },
      expression: "roundLocal.name"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Best of",
      "label-for": "bestOf"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "bestOf",
      "options": _vm.matchFormatOptions,
      "reduce": function reduce(format) {
        return format.code;
      }
    },
    model: {
      value: _vm.roundLocal.format_id,
      callback: function callback($$v) {
        _vm.$set(_vm.roundLocal, "format_id", $$v);
      },
      expression: "roundLocal.format_id"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }