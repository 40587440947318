var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.recursiveBracket ? _c('div', {
    staticClass: "vtb-wrapper flex-column"
  }, [_c('b-row', {
    staticClass: "m-0 flex-nowrap"
  }, _vm._l(_vm.roundsData, function (round) {
    return _c('b-col', {
      key: round.id,
      staticClass: "round-container",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.roundSettingsClick(round.id);
        }
      }
    }, [_c('p', {
      staticClass: "mt-1 ml-1 d-flex justify-content-around"
    }, [_c('span', [_vm._v(_vm._s(round.name || "Round ".concat(round.index)))]), _vm.showRoundFormat ? _c('span', {
      staticClass: "ml-1",
      staticStyle: {
        "font-size": "12px",
        "color": "#7367f0"
      }
    }, [_vm._v("[ Best of " + _vm._s(round.format || '1') + " ]")]) : _vm._e()])]);
  }), 1), _c('b-row', {
    staticClass: "m-0"
  }, [_c('bracket-node', {
    attrs: {
      "bracket-node": _vm.recursiveBracket,
      "highlighted-player-id": _vm.highlightedPlayerId,
      "show-edit-results": true,
      "organizer-mode": true,
      "loading-match-id": _vm.loadingMatchId,
      "is-loading-match": _vm.isLoadingMatch
    },
    on: {
      "onDeselectedPlayer": _vm.unhighlightPlayer,
      "onMatchResultClick": _vm.matchResultClick
    },
    scopedSlots: _vm._u([{
      key: "player",
      fn: function fn(_ref) {
        var player = _ref.player;
        return [_vm._t("player", null, {
          "player": player
        })];
      }
    }, {
      key: "player-extension-bottom",
      fn: function fn(_ref2) {
        var match = _ref2.match;
        return [_vm._t("player-extension-bottom", null, {
          "match": match
        })];
      }
    }], null, true)
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }